import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ReviewsCust.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Chimney3 from '../../Images/PhotoImg3.jpg'
import bgnobgs from '../../Images/bgnobgs.png'

export default function ReviewsCust() {
    const navigate = useNavigate()


    return (
        <div className="ReviewsCust">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Customer Reviews | Santa Ana Air Duct Cleaning - Hear What Our Clients Say</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Customer Reviews | Santa Ana Air Duct Cleaning - Hear What Our Clients Say" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Customer Reviews | Santa Ana Air Duct Cleaning - Hear What Our Clients Say" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.santaanaairduct.org/Reviews by our customers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Read customer reviews and testimonials to see why Santa Ana Air Duct Cleaning is trusted for Air Duct Cleaning, inspections, and repairs. Discover the experiences of our satisfied clients." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Read customer reviews and testimonials to see why Santa Ana Air Duct Cleaning is trusted for Air Duct Cleaning, inspections, and repairs. Discover the experiences of our satisfied clients." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Customer Reviews | Santa Ana Air Duct Cleaning - Hear What Our Clients Say" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rain Cap Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Air Duct Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="ReviewsCustTitle">
                <h1>What Our Customers Are Saying</h1>
                <h2>Find out why Santa Ana Air Duct Cleaning is the nation’s go-to Air Duct company. As industry leaders in Air Duct service and repair, we manage everything from routine installations and maintenance to urgent emergencies requiring immediate attention. Our expert plumbers are available around the clock, including weekends and holidays, ensuring your home’s Air Duct operates smoothly year-round. Trust the professionals at Santa Ana Air Duct Cleaning to keep your Air Duct system in top shape. For reliable service you can count on, contact Santa Ana Air Duct Cleaning today for all your Air Duct needs.</h2>
            </div>
            <div className="ReviewsCustLsit">
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team at Santa Ana Air Duct Cleaning did a fantastic job. Their <a href="/service/Air-Duct-Cleaning">air duct cleaning</a> service left my home feeling fresher, and I can already feel the difference in air quality. They were professional, punctual, and took the time to explain the process. I’m very satisfied with the results and will definitely use them again!"</h2>
                    <h4>Anna P.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had them clean the ducts in my apartment, and I couldn’t be happier with the service. Their <a href="/service/Apartment-Air-Duct-Cleaning">apartment air duct cleaning</a> was thorough, and they didn’t leave a speck of dust behind. The team was friendly and efficient, and they respected my home throughout the process. Highly recommend!"</h2>
                    <h4>Brian S.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning provided excellent service when I needed my HVAC system cleaned. Their <a href="/service/HVAC-System-Cleaning">HVAC system cleaning</a> was detailed and comprehensive. They took the time to ensure every component was spotless, and now my system is running more efficiently than ever. Their attention to detail is unmatched!"</h2>
                    <h4>Cynthia L.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I noticed mold in my air ducts and called Santa Ana Air Duct Cleaning right away. Their <a href="/service/Mold-Removal-from-Air-Ducts">mold removal</a> service was prompt and effective. The technicians were knowledgeable and explained the entire process, ensuring that my home was safe and mold-free. I’m relieved and grateful for their professionalism."</h2>
                    <h4>David R.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The air quality in my home has improved significantly since Santa Ana Air Duct Cleaning serviced my ducts. Their <a href="/service/Air-Duct-Sanitization">air duct sanitization</a> was thorough, and I can breathe easier knowing that the air circulating in my home is clean. The technicians were courteous, on time, and left everything spotless. Highly recommend!"</h2>
                    <h4>Emily K.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had issues with dust buildup and allergies, so I contacted Santa Ana Air Duct Cleaning. Their <a href="/service/Air-Duct-Cleaning-for-Allergies">air duct cleaning for allergies</a> has made a noticeable difference in my home. The team was professional, explained the process, and made sure everything was done to perfection. I’m very pleased with the service!"</h2>
                    <h4>Frank M.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The dryer vent in my home was clogged, and Santa Ana Air Duct Cleaning handled the job expertly. Their <a href="/service/Dryer-Vent-Cleaning">dryer vent cleaning</a> service was quick and efficient, and they even gave me tips to keep it maintained. The technicians were friendly and professional, making the whole process smooth and stress-free."</h2>
                    <h4>Grace T.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had my commercial building’s air ducts cleaned by Santa Ana Air Duct Cleaning, and they exceeded my expectations. Their <a href="/service/Commercial-Air-Duct-Cleaning">commercial air duct cleaning</a> service was detailed and thorough, and the team worked around our schedule to minimize disruption. The air quality has improved dramatically, and I couldn’t be happier with the results."</h2>
                    <h4>Henry K.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning did an excellent job inspecting my air ducts. Their <a href="/service/Air-Duct-Inspection">air duct inspection</a> service was detailed, and they identified issues that I wasn’t even aware of. They provided a clear report and recommendations for maintenance. I appreciated their honesty and expertise."</h2>
                    <h4>Isabella P.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I needed my air ducts repaired and sealed, and Santa Ana Air Duct Cleaning was the perfect choice. Their <a href="/service/Ductwork-Repair-and-Sealing">ductwork repair and sealing</a> service was professional and thorough. They fixed the issues efficiently, and I’ve noticed a significant improvement in the air quality and energy efficiency in my home. Highly recommend their services!"</h2>
                    <h4>Jack F.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning provided exceptional service when they cleaned my furnace ducts. Their <a href="/service/Furnace-Duct-Cleaning">furnace duct cleaning</a> was thorough, and the technicians were knowledgeable and friendly. They explained the importance of keeping these ducts clean, and I’m impressed with how much better my heating system works now."</h2>
                    <h4>Katherine V.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"After years of neglect, my home’s air ducts desperately needed cleaning. Santa Ana Air Duct Cleaning’s <a href="/service/Residential-Air-Duct-Cleaning">residential air duct cleaning</a> service was exactly what I needed. The team was professional, did a great job, and left my home cleaner than they found it. I can’t believe the difference it has made in the air quality!"</h2>
                    <h4>Liam J.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I hired Santa Ana Air Duct Cleaning to clean the ducts in our office, and they did an outstanding job. Their <a href="/service/Commercial-Air-Duct-Cleaning">commercial air duct cleaning</a> service was efficient, and they worked around our business hours to minimize disruption. The air feels much cleaner, and we’ve noticed a reduction in dust. Excellent service!"</h2>
                    <h4>Megan B.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"My HVAC system was overdue for a cleaning, and Santa Ana Air Duct Cleaning delivered exceptional results. Their <a href="/service/HVAC-System-Cleaning">HVAC system cleaning</a> service was comprehensive, and the technicians were professional and efficient. I’m very happy with the improved performance of my system and the overall air quality in my home."</h2>
                    <h4>Noah E.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning did a fantastic job cleaning and sanitizing my air ducts. Their <a href="/service/Air-Duct-Sanitization">air duct sanitization</a> service left my home smelling fresh, and I can already feel the difference in the air quality. The team was friendly, on time, and left no mess behind. I highly recommend their services!"</h2>
                    <h4>Olivia G.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I was experiencing allergy symptoms, so I decided to have my air ducts cleaned. Santa Ana Air Duct Cleaning’s <a href="/service/Air-Duct-Cleaning-for-Allergies">air duct cleaning for allergies</a> was exactly what I needed. The technicians were knowledgeable and thorough, and I’ve noticed a significant improvement in my symptoms. I’m very satisfied with their work!"</h2>
                    <h4>Patrick M.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"My air ducts were long overdue for maintenance, and Santa Ana Air Duct Cleaning did an excellent job. Their <a href="/service/Air-Duct-Maintenance">air duct maintenance</a> service was thorough, and the technicians were friendly and professional. They took the time to explain the process and answered all my questions. I will definitely use them again!"</h2>
                    <h4>Quinn D.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning was a pleasure to work with. Their <a href="/service/Ductwork-Repair-and-Sealing">ductwork repair and sealing</a> service was efficient, and the team was very knowledgeable. They repaired some issues I wasn’t aware of, and I’ve noticed an immediate improvement in air quality and energy efficiency. Highly recommend!"</h2>
                    <h4>Rebecca S.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had Santa Ana Air Duct Cleaning clean the ducts in my home, and I’m thrilled with the results. Their <a href="/service/Residential-Air-Duct-Cleaning">residential air duct cleaning</a> service was professional and thorough. The technicians were friendly, arrived on time, and took care of everything efficiently. My home feels much fresher now!"</h2>
                    <h4>Samuel W.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team at Santa Ana Air Duct Cleaning did an excellent job cleaning our AC ducts. Their <a href="/service/AC-Duct-Cleaning">AC duct cleaning</a> service was thorough, and they made sure to clean up after themselves. The air in our home feels noticeably cleaner, and I couldn’t be happier with the service!"</h2>
                    <h4>Tina C.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I needed a thorough cleaning of my air ducts, and Santa Ana Air Duct Cleaning delivered. Their <a href="/service/Air-Duct-Cleaning">air duct cleaning</a> service was detailed and professional. The technicians were knowledgeable and took the time to explain everything to me. I’m very pleased with the outcome and will definitely use them again!"</h2>
                    <h4>Ulysses P.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had Santa Ana Air Duct Cleaning sanitize my air ducts, and the difference is incredible. Their <a href="/service/Air-Duct-Sanitization">air duct sanitization</a> service was thorough, and the technicians were very professional. My home feels much fresher, and I have peace of mind knowing that the air is clean and safe."</h2>
                    <h4>Vanessa M.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning provided exceptional service when I needed my dryer vent cleaned. Their <a href="/service/Dryer-Vent-Cleaning">dryer vent cleaning</a> was efficient, and the technicians were very knowledgeable. They even gave me tips on how to maintain the vent. I’m very satisfied with the service and will definitely use them again."</h2>
                    <h4>William J.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I hired Santa Ana Air Duct Cleaning to clean the ducts in my commercial building, and they did a fantastic job. Their <a href="/service/Commercial-Air-Duct-Cleaning">commercial air duct cleaning</a> service was efficient, and they worked around our business hours to minimize disruption. The air quality has improved significantly, and I’m very pleased with the results."</h2>
                    <h4>Xander L.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning did an outstanding job cleaning and sealing my air ducts. Their <a href="/service/Ductwork-Repair-and-Sealing">ductwork repair and sealing</a> service was thorough, and the technicians were very professional. I’ve noticed a significant improvement in air quality and energy efficiency since the service. Highly recommend!"</h2>
                    <h4>Yasmine K.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"My air ducts were in dire need of cleaning, and Santa Ana Air Duct Cleaning did a wonderful job. Their <a href="/service/Air-Duct-Cleaning">air duct cleaning</a> service was professional, and the technicians were very thorough. They explained the entire process and made sure everything was spotless before they left. I’m very happy with the results!"</h2>
                    <h4>Zachary N.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team at Santa Ana Air Duct Cleaning was professional and efficient. Their <a href="/service/AC-Duct-Cleaning">AC duct cleaning</a> service was exactly what my home needed. The air feels cleaner, and my AC system is running more efficiently. The technicians were friendly and did a great job. I highly recommend their services!"</h2>
                    <h4>Abigail F.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Santa Ana Air Duct Cleaning did an excellent job cleaning the furnace ducts in my home. Their <a href="/service/Furnace-Duct-Cleaning">furnace duct cleaning</a> service was thorough, and the technicians were knowledgeable and professional. My heating system is working much better now, and the air quality has improved significantly. Very satisfied!"</h2>
                    <h4>Blake T.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had Santa Ana Air Duct Cleaning inspect my air ducts, and they provided a detailed report on their condition. Their <a href="/service/Air-Duct-Inspection">air duct inspection</a> service was thorough, and the technicians were very knowledgeable. They gave me recommendations for maintenance, and I’m very happy with the service. Highly recommend!"</h2>
                    <h4>Charlotte V.</h4>
                </div>

                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The air ducts in my home were long overdue for cleaning, and Santa Ana Air Duct Cleaning did a fantastic job. Their <a href="/service/Residential-Air-Duct-Cleaning">residential air duct cleaning</a> service was thorough, and the technicians were friendly and professional. My home feels much fresher now, and I couldn’t be happier with the results!"</h2>
                    <h4>Damian R.</h4>
                </div>

            </div>

            <NavigatorPath />

        </div>
    )
}

